import React from "react";
import under_construction from "./images/under_construction.png";

function App() {
  return (
    <div className="flex h-screen justify-center items-center flex-col bg-slate-100">
      <div className="flex flex-col h-[80vh] w-[80vw] gap-2 items-center m-8 bg-slate-200 rounded-xl shadow-xl">
        <img
          src={under_construction}
          className="rounded-md shadow-xl mx-4 mt-12 max-h-[70%] object-contain"
          alt="under construction"
        ></img>
        <h1 className="text-bold text-2xl text-center">
          Site under construction
        </h1>
      </div>
    </div>
  );
}

export default App;
